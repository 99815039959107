<template>
  <div class="owner-patients-information-confirm">
    <div class="page-title">
      <base-page-title>{{
        $t('ownerPatientsInformationConfirm.pageTitle')
      }}</base-page-title>
    </div>
    <div class="owner-patients-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="information-list-owner">
      <information-list-owner
        :owner-data="owner"
        :patient-list="patientsForDisplay"
      />
    </div>
    <div class="change-button">
      <base-decision-button @click="changeOwnerPatients" :disabled="waitFlg">{{
        $t('common.buttonConfirmChanges')
      }}</base-decision-button>
    </div>
    <div class="back-button">
      <base-cancel-button @click="backOwnerPatientsInformationChange">{{
        $t('common.buttonModifyChanges')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :type="'failure'"
      :title="$t('common.error')"
      :buttons="buttons.map(v => $t(v))"
      >{{ $t(popupMessage) }}</announce-popup
    >
    <unsaved-leave-popup @cancel="okLeave" />
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import InformationListOwner from '@/components/parts/organisms/InformationListOwner.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { getAxiosObject } from '@/utils/library'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'

export default {
  name: 'OwnerPatientsInformationConfirm',

  components: {
    BasePageTitle,
    ProgressBar,
    InformationListOwner,
    BaseDecisionButton,
    BaseCancelButton,
    AnnouncePopup,
    UnsavedLeavePopup
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      progressBarData: {
        textList: [
          'common.progressBarModifyOwner',
          'common.progressBarConfirm',
          'common.progressBarFinish'
        ],
        barWidthPc: 'owner-info-edit-screen',
        textOrangeNumber: 2
      },
      alertFlg: false,
      popupMessage: '',
      buttons: ['common.close'],
      waitFlg: false
    }
  },

  computed: {
    owner() {
      return this.$store.getters['owner/getChangeOwner']
    },
    patients() {
      return this.$store.getters['patient/getChangePatients']
    },
    species() {
      return this.$store.getters['species/getDataIncludeDelData']
    },
    patientsForDisplay() {
      const patientsForDisplay = this.patients.map(patient => {
        const species = this.species.find(v => v.id === patient.speciesId)
        const speciesId = species
          ? this.$i18n.locale === 'en'
            ? species.nameEnglish || species.name
            : species.name
          : ''
        return { ...patient, speciesId }
      })
      return patientsForDisplay
    }
  },

  methods: {
    async changeOwnerPatients() {
      this.waitFlg = true
      try {
        const newPatients = this.patients
          .filter(obj => {
            return !obj.id
          })
          .map(obj => {
            delete obj.number
            return obj
          })
        const changePatients = this.patients
          .filter(obj => {
            return obj.id
          })
          .map(obj => {
            delete obj.number
            return obj
          })
        const axiosObject = getAxiosObject()
        const response = await axiosObject.put('/owner-patients', {
          changeOwner: {
            ...this.owner,
            englishFlg: this.$i18n.locale === 'en' ? 1 : 0
          },
          changePatients: changePatients,
          newPatients: newPatients,
          delPatients: this.$store.getters['patient/getDelPatientsIds']
        })
        this.$store.dispatch('owner/addOwner', response.data.owner)
        this.$store.dispatch('patient/addPatients', response.data.patients)
        this.$store.dispatch('owner/setChangeOwner')
        this.$store.dispatch('patient/setChangePatients')
        this.$store.dispatch('patient/setDelPatientsIds')
        this.$store.dispatch('auth/scenarioReset')
        this.$router.push({ name: 'OwnerPatientsInformationCompletion' })
      } catch (ex) {
        if (ex.response?.status === 401) {
          this.buttons = ['common.buttonToLogin']
        } else {
          this.buttons = ['common.close']
          if (
            ex.response?.data?.message === 'already used' &&
            ex.response?.data?.extra.field === 'email'
          ) {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessageEmail'
          } else if (
            ex.response?.data?.message === 'currently used' &&
            ex.response?.data?.extra === 'medicalRecords'
          ) {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessageKarte'
          } else if (
            ex.response?.data?.message === 'currently used' &&
            ex.response?.data?.extra === 'reservationRequests'
          ) {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessageReservationRequest'
          } else if (
            ex.response?.data?.message === 'currently used' &&
            ex.response?.data?.extra === 'reservations'
          ) {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessageReservation'
          } else if (
            ex.response?.data?.message === 'no data' &&
            ex.response?.data?.extra === 'patients'
          ) {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessagePatient'
          } else {
            this.popupMessage =
              'ownerPatientsInformationConfirm.popupMessageError'
          }
        }
        this.alertFlg = true
        this.waitFlg = false
      }
    },
    backOwnerPatientsInformationChange() {
      this.$router.back()
    },
    okLeave() {
      this.$store.dispatch('petorelu/okLeave')
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-patients-information-confirm {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 69px;
  }
  > .owner-patients-progress-bar {
    margin-top: 28px;
  }
  > .information-list-owner {
    margin-top: 58px;
  }
  > .change-button {
    margin-top: 80px;
  }
  > .back-button {
    margin-top: 23px;
  }
}
@media (max-width: $global-media-width) {
  .owner-patients-information-confirm {
    > .information-list-owner {
      margin-top: 82px;
    }
    > .change-button {
      margin-top: 85px;
    }
  }
}
</style>
