<template>
  <div class="unsaved-leave-popup">
    <announce-popup
      v-if="showFlg || closeReservationPopupFlg"
      :title="$t('parts.confirmNavigation')"
      :type="'alert'"
      :buttons="[$t('parts.stayOnThisPage'), $t('parts.leaveThisPage')]"
      @cancel="cancel"
      @decision="decision"
      >{{ $t('parts.confirmNavigationMessage1') }}<br />{{
        $t('parts.confirmNavigationMessage2')
      }}</announce-popup
    >
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
export default {
  name: 'UnsavedLeavePopup',
  components: {
    AnnouncePopup
  },
  props: {
    closeReservationPopupFlg: { type: Boolean, default: false }
  },
  computed: {
    showFlg() {
      return this.$store.getters['petorelu/get'].leaveDialogShow
    }
  },
  beforeDestroy() {
    this.$store.dispatch('petorelu/leaveReset')
  },
  methods: {
    cancel() {
      this.$store.dispatch('petorelu/hideLeaveDialog')
      this.$emit('cancel')
    },
    decision() {
      this.$store.dispatch('petorelu/hideLeaveDialog')
      this.$emit('decision')
      const nextRoute = this.$store.getters['petorelu/get'].nextRoute
      if (nextRoute !== null) {
        nextRoute()
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
